.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto; /* 添加这行代码，实现水平居中 */
}

/* 中等屏幕设备（横屏平板，768px 至 992px） */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .App {
        max-width: 640px
    }
}
/* 大屏幕设备（笔记本电脑，992px 至 1200px） */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .App {
        max-width: 640px
    }
}

/* 超大屏幕设备（台式机，1200px 以上） */
@media only screen and (min-width: 1200px) {
    .App {
        max-width: 640px;
    }
}

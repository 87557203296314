.letterRoot {
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/ic_letter_bg.png');
    background-size: cover; /* 使背景图片覆盖整个元素 */
    background-position: top; /* 将背景图片居中显示 */
    background-repeat: no-repeat; /* 禁止背景图片重复 */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.submit-box {
    margin-top: 25px;
    width: calc(100% - 60px);
    background: linear-gradient( 119deg, #CB0A0E 0%, #A81213 100%);
    color: #FFF;
    height: 50px;
    border-radius: 25px;
    text-align: center; /* 让文本水平居中 */
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
}
.letterRoot .detail-box {
    margin-top: 25px;
    background-color: #FFF;
    border-radius: 8px;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
}
.letterRoot .detail-box .top-box {
    display: flex;
    flex-direction: column;
    padding: 16px;
}
.letterRoot .detail-box .name-box {
    display: flex;
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}
.letterRoot .detail-box .idcard-box {
    display: flex;
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.letterRoot .bottom-box {
    font-size: 11px;
    color: #777570;
    font-weight: 400;
    position: absolute;
    bottom: 50px;
    width: 80%;
}

/* 当 Input 组件有值时，设置字体大小为 15px */
Input:not(:placeholder-shown) {
    --font-size: 15px;
    --text-align: right;
}

/* 当 Input 组件没有值时，设置字体大小为 9px */
Input:placeholder-shown {
    --font-size: 9px;
    --text-align: right;
}
